import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function PricingAllBasicPreview(props) {
  const { PricingBasic, PricingPrimary, PricingBasicContactUs } = props

  return (
    <>
      {/*<!-- Component: All Basic--> */}
      <div className="container px-6 m-auto">
        <div className="grid grid-cols-4 gap-6 lg:grid-cols-12">
          <div className="col-span-4">
            <CopyComponent
              copyToClipboardCode={PricingBasic}
              componentName="PricingBasic"
            >
              <div className="w-full max-w-sm mx-auto overflow-hidden bg-white rounded shadow-lg lg:max-md-full text-slate-500 shadow-slate-200">
                <div className="flex flex-col">
                  <header className="flex flex-col gap-6 p-6 text-slate-400">
                    <h3 className="text-xl font-bold text-slate-700">
                      Basic
                      <span className="block text-sm font-normal text-slate-400">
                        Ideal for individual developers.
                      </span>
                    </h3>
                    <h4>
                      <span className="text-3xl">$</span>
                      <span className="text-5xl font-bold tracking-tighter transition-all duration-300 text-slate-700 lg:text-6xl">
                        15
                      </span>
                      <span className="text-sm">/month</span>
                    </h4>
                    <button className="inline-flex items-center justify-center w-full h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded shadow-lg whitespace-nowrap bg-emerald-500 shadow-emerald-100 hover:bg-emerald-600 hover:shadow-md hover:shadow-emerald-100 focus:bg-emerald-700 focus:shadow-md focus:shadow-emerald-100 focus-visible:outline-none">
                      <span>Try for free</span>
                    </button>
                  </header>
                </div>
              </div>
            </CopyComponent>
          </div>
          <div className="col-span-4">
            <CopyComponent
              copyToClipboardCode={PricingPrimary}
              componentName="PricingPrimary"
            >
              <div className="w-full max-w-sm mx-auto overflow-hidden rounded shadow-xl lg:max-md-full bg-emerald-500 text-emerald-100 shadow-emerald-100">
                <div className="flex flex-col">
                  <header className="flex flex-col gap-6 p-6 text-emerald-200">
                    <h3 className="text-xl font-bold text-emerald-50">
                      Team
                      <span className="block text-sm font-normal text-emerald-200">
                        Ideal for a small team.
                      </span>
                    </h3>
                    <h4>
                      <span className="text-3xl">$</span>
                      <span className="text-5xl font-bold tracking-tighter transition-all duration-300 text-emerald-50 lg:text-6xl">
                        25
                      </span>
                      <span className="text-sm">/month</span>
                    </h4>
                    <button className="inline-flex items-center justify-center w-full h-12 gap-2 px-6 text-sm font-medium tracking-wide transition duration-300 rounded shadow-lg whitespace-nowrap bg-emerald-50 text-emerald-500 shadow-emerald-600 hover:text-emerald-600 hover:shadow-md hover:shadow-emerald-600 focus:text-emerald-700 focus:shadow-md focus:shadow-emerald-600 focus-visible:outline-none">
                      <span>Try for free</span>
                    </button>
                  </header>
                </div>
              </div>
            </CopyComponent>
          </div>
          <div className="col-span-4">
            <CopyComponent
              copyToClipboardCode={PricingBasicContactUs}
              componentName="PricingBasicContactUs"
            >
              <div className="w-full max-w-sm mx-auto overflow-hidden bg-white rounded shadow-lg lg:max-md-full text-slate-500 shadow-slate-200">
                <div className="flex flex-col">
                  <header className="flex flex-col gap-6 p-6 text-slate-400">
                    <h3 className="text-xl font-bold text-slate-700">
                      Premium
                      <span className="block text-sm font-normal text-slate-400">
                        Ideal for organizations.
                      </span>
                    </h3>
                    <h4>
                      <span className="text-3xl">$</span>
                      <span className="text-5xl font-bold tracking-tighter transition-all duration-300 text-slate-700 lg:text-6xl">
                        85
                      </span>
                      <span className="text-sm">/month</span>
                    </h4>
                    <button className="inline-flex items-center justify-center w-full h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded shadow-lg whitespace-nowrap bg-emerald-500 shadow-emerald-100 hover:bg-emerald-600 hover:shadow-md hover:shadow-emerald-100 focus:bg-emerald-700 focus:shadow-md focus:shadow-emerald-200 focus-visible:outline-none">
                      <span>Contact Us</span>
                    </button>
                  </header>
                </div>
              </div>
            </CopyComponent>
          </div>
        </div>
      </div>
      {/*<!-- End All Basic--> */}
    </>
  )
}
