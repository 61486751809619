import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function PricingAllGemsPreview(props) {
  const { PricingGemEmerald, PricingGemRuby, PricingGemDiamond } = props

  return (
    <>
      {/*<!-- Component: All Basic--> */}
      <div className="container px-6 m-auto">
        <div className="grid grid-cols-4 gap-6 lg:grid-cols-12">
          <div className="col-span-4">
            <CopyComponent
              copyToClipboardCode={PricingGemEmerald}
              componentName="PricingGemEmerald"
              extraClasses="h-[105%]"
              disableColorPicker
            >
              <div className="relative w-full max-w-sm mx-auto mt-20 text-center bg-white rounded shadow-lg shadow-wuiSlate-20 lg:max-md-full group text-wuiSlate-500">
                <img
                  src="https://Tailwindmix.b-cdn.net/pricing-tables/emerald.png"
                  alt="emerald"
                  className="absolute left-1/2 block w-32 -translate-x-1/2 -translate-y-1/2 drop-shadow-[0_16px_16px_#84cc1650] transition-transform duration-700 group-hover:-translate-y-2/3"
                />
                <div className="flex flex-col">
                  <header className="flex flex-col gap-6 p-6 pt-28 text-wuiSlate-400">
                    <h3 className="text-xl font-medium uppercase text-wuiEmerald-500">
                      Emerald
                    </h3>
                  </header>
                  <div className="w-3 h-3 mx-auto rounded-full bg-wuiEmerald-500"></div>
                  <div className="p-6">
                    <ul className="space-y-4">
                      <li className="w-full gap-2">1 public project</li>
                      <li className="w-full gap-2">public working space</li>
                      <li className="w-full gap-2">unlimited pages</li>
                      <li className="w-full gap-2">5 revisions</li>
                    </ul>
                  </div>
                  <footer>
                    <button className="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 translate-y-1/2 rounded shadow-xl whitespace-nowrap bg-wuiEmerald-500 shadow-wuiEmerald-100 hover:bg-wuiEmerald-600 focus:bg-wuiEmerald-700 focus-visible:outline-none">
                      <span>Start now</span>- <span>$0</span>
                    </button>
                  </footer>
                </div>
              </div>
            </CopyComponent>
          </div>

          <div className="col-span-4">
            <CopyComponent
              copyToClipboardCode={PricingGemRuby}
              componentName="PricingGemRuby"
              extraClasses="h-[105%]"
              disableColorPicker
            >
              <div className="relative w-full max-w-sm mx-auto mt-20 text-center bg-white rounded shadow-lg shadow-wuiSlate-20 lg:max-md-full group text-wuiSlate-500">
                <img
                  src="https://Tailwindmix.b-cdn.net/pricing-tables/ruby.png"
                  alt="ruby"
                  className="absolute left-1/2 block w-32 -translate-x-1/2 -translate-y-1/2 drop-shadow-[0_16px_16px_#e73b3a40] transition-transform duration-700 group-hover:-translate-y-2/3"
                />
                <div className="flex flex-col">
                  <header className="flex flex-col gap-6 p-6 pt-28 text-wuiSlate-400">
                    <h3 className="text-xl font-medium uppercase text-wuiRed-500">
                      Ruby
                    </h3>
                  </header>
                  <div className="w-3 h-3 mx-auto rounded-full bg-wuiRed-500"></div>
                  <div className="p-6">
                    <ul className="space-y-4">
                      <li className="w-full gap-2">4 public project</li>
                      <li className="w-full gap-2">private working space</li>
                      <li className="w-full gap-2">unlimited pages</li>
                      <li className="w-full gap-2">10 revisions</li>
                    </ul>
                  </div>
                  <footer>
                    <button className="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 translate-y-1/2 rounded shadow-xl whitespace-nowrap bg-wuiRed-500 shadow-wuiRed-100 hover:bg-wuiRed-600 focus:bg-wuiRed-700 focus-visible:outline-none">
                      <span>Start now</span>- <span>$5</span>
                    </button>
                  </footer>
                </div>
              </div>
            </CopyComponent>
          </div>

          <div className="col-span-4">
            <CopyComponent
              copyToClipboardCode={PricingGemDiamond}
              componentName="PricingGemDiamond"
              extraClasses="h-[105%]"
              disableColorPicker
            >
              <div className="relative w-full max-w-sm mx-auto mt-20 text-center bg-white rounded shadow-lg shadow-wuiSlate-20 lg:max-md-full group text-wuiSlate-500">
                <img
                  src="https://Tailwindmix.b-cdn.net/pricing-tables/diamond.png"
                  alt="diamond"
                  className="absolute left-1/2 block w-32 -translate-x-1/2 -translate-y-1/2 drop-shadow-[0_16px_16px_#73e7ff6b] transition-transform duration-700 group-hover:-translate-y-2/3"
                />
                <div className="flex flex-col">
                  <header className="flex flex-col gap-6 p-6 pt-28 text-wuiSlate-400">
                    <h3 className="text-xl font-medium uppercase text-wuiCyan-500">
                      Diamond
                    </h3>
                  </header>
                  <div className="w-3 h-3 mx-auto rounded-full bg-wuiCyan-500"></div>
                  <div className="p-6">
                    <ul className="space-y-4">
                      <li className="w-full gap-2">unlimited projects</li>
                      <li className="w-full gap-2">private working space</li>
                      <li className="w-full gap-2">unlimited pages</li>
                      <li className="w-full gap-2">unlimited revisions</li>
                    </ul>
                  </div>
                  <footer>
                    <button className="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 translate-y-1/2 rounded shadow-xl whitespace-nowrap bg-wuiCyan-500 shadow-wuiCyan-100 hover:bg-wuiCyan-600 focus:bg-wuiCyan-700 focus-visible:outline-none">
                      <span>Start now</span>- <span>$10</span>
                    </button>
                  </footer>
                </div>
              </div>
            </CopyComponent>
          </div>
        </div>
      </div>
      {/*<!-- End All Basic--> */}
    </>
  )
}
