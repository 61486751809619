import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// SEO Image
import ogImage from "../../../images/seo/pricing-tables/pricing-tables.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
import HeroComponentPricingTable from "../../../library/components/pricing/react/_preview/HeroComponentPricingTable"
import PricingAllBasicPreview from "../../../library/components/pricing/react/_preview/basic"
import PricingAllDetailedPreview from "../../../library/components/pricing/react/_preview/detailed"
import PricingAllGemsPreview from "../../../library/components/pricing/react/_preview/gems"
// All

// Basic
const PricingBasicJsx = require("!!raw-loader!../../../library/components/pricing/react/basic.jsx")
const PricingBasicHTML = require("!!raw-loader!../../../library/components/pricing/html/basic.html")

const PricingPrimaryJsx = require("!!raw-loader!../../../library/components/pricing/react/primary.jsx")
const PricingPrimaryHTML = require("!!raw-loader!../../../library/components/pricing/html/primary.html")

const PricingBasicContactUsJsx = require("!!raw-loader!../../../library/components/pricing/react/basic-contact-us.jsx")
const PricingBasicContactUsHTML = require("!!raw-loader!../../../library/components/pricing/html/basic-contact-us.html")

// Detailed
const PricingBasicDetailedJsx = require("!!raw-loader!../../../library/components/pricing/react/basic-detailed.jsx")
const PricingBasicDetailedHTML = require("!!raw-loader!../../../library/components/pricing/html/basic-detailed.html")

const PricingPrimaryDetailedJsx = require("!!raw-loader!../../../library/components/pricing/react/primary-detailed.jsx")
const PricingPrimaryDetailedHTML = require("!!raw-loader!../../../library/components/pricing/html/primary-detailed.html")

const PricingPrimaryDetailedContactUsJsx = require("!!raw-loader!../../../library/components/pricing/react/primary-detailed-contact-us.jsx")
const PricingPrimaryDetailedContactUsHTML = require("!!raw-loader!../../../library/components/pricing/html/primary-detailed-contact-us.html")

// Gems
const PricingGemEmeraldJsx = require("!!raw-loader!../../../library/components/pricing/react/gem-emerald.jsx")
const PricingGemEmeraldHTML = require("!!raw-loader!../../../library/components/pricing/html/gem-emerald.html")

const PricingGemRubyJsx = require("!!raw-loader!../../../library/components/pricing/react/gem-ruby.jsx")
const PricingGemRubyHTML = require("!!raw-loader!../../../library/components/pricing/html/gem-ruby.html")

const PricingGemDiamondJsx = require("!!raw-loader!../../../library/components/pricing/react/gem-diamond.jsx")
const PricingGemDiamondHTML = require("!!raw-loader!../../../library/components/pricing/html/gem-diamond.html")

export default function PricingTablesPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "PricingAllBasic",
      title: "Basic",
      active_tab: 1,
    },
    {
      component_name: "PricingAllDetailed",
      title: "Detailed",
      active_tab: 1,
    },
    {
      component_name: "PricingAllGems",
      title: "Gems",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Pricing Tables - WindUI Component Library"
        ogtitle="Tailwind CSS Pricing Tables - WindUI Component Library"
        description="Pricing table components, allow users to get an overview of what pricing plans are offered and how they differ for a service. Built with Tailwind CSS by WindUI."
        ogdescription="Pricing table components, allow users to get an overview of what pricing plans are offered and how they differ for a service. Built with Tailwind CSS by WindUI."
        ogimage={ogImage}
        url="components/pricing/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Pricing Tables, Pricing Tables, Pricing Table Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Pricing Tables</h1>
          <p>
            The pricing table component allows users to get an overview of what
            pricing plans are offered and how they differ, on a product or
            service.
          </p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-wuiSlate-50 p-4 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16 [&>*]:w-full">
              <HeroComponentPricingTable />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="PricingAllBasic">Basic</h3>

          <PreviewBlock
            id="PricingAllBasic"
            HtmlComponent={PricingBasicHTML.default}
            JsxComponent={PricingBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-wuiSlate-50 p-4 pb-12 sm:p-16 sm:pb-16 [&>*]:w-full">
              <PricingAllBasicPreview
                PricingBasic={
                  activeTabs[0].active_tab === 1
                    ? PricingBasicHTML.default
                    : PricingBasicJsx.default
                }
                PricingPrimary={
                  activeTabs[0].active_tab === 1
                    ? PricingPrimaryHTML.default
                    : PricingPrimaryJsx.default
                }
                PricingBasicContactUs={
                  activeTabs[0].active_tab === 1
                    ? PricingBasicContactUsHTML.default
                    : PricingBasicContactUsJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="PricingAllDetailed">Detailed</h3>

          <PreviewBlock
            id="PricingAllDetailed"
            HtmlComponent={PricingBasicDetailedHTML.default}
            JsxComponent={PricingBasicDetailedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-wuiSlate-50 p-4 pb-16 sm:p-16 sm:pb-16 [&>*]:w-full">
              <PricingAllDetailedPreview
                PricingBasicDetailed={
                  activeTabs[1].active_tab === 1
                    ? PricingBasicDetailedHTML.default
                    : PricingBasicDetailedJsx.default
                }
                PricingPrimaryDetailed={
                  activeTabs[1].active_tab === 1
                    ? PricingPrimaryDetailedHTML.default
                    : PricingPrimaryDetailedJsx.default
                }
                PricingPrimaryDetailedContactUs={
                  activeTabs[1].active_tab === 1
                    ? PricingPrimaryDetailedContactUsHTML.default
                    : PricingPrimaryDetailedContactUsJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="PricingAllGems">Gems</h3>

          <PreviewBlock
            id="PricingAllGems"
            HtmlComponent={PricingGemEmeraldHTML.default}
            JsxComponent={PricingGemEmeraldJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center overflow-hidden rounded-xl bg-wuiSlate-50 p-4 pb-16 sm:p-16 sm:pb-16 [&>*]:w-full">
              <PricingAllGemsPreview
                PricingGemEmerald={
                  activeTabs[2].active_tab === 1
                    ? PricingGemEmeraldHTML.default
                    : PricingGemEmeraldJsx.default
                }
                PricingGemRuby={
                  activeTabs[2].active_tab === 1
                    ? PricingGemRubyHTML.default
                    : PricingGemRubyJsx.default
                }
                PricingGemDiamond={
                  activeTabs[2].active_tab === 1
                    ? PricingGemDiamondHTML.default
                    : PricingGemDiamondJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>Use the pricing table component:</p>
          <ul>
            <li>
              When there are more than one versions of a product to choose
              among.
            </li>
            <li>
              When you want to allow users to have an overview of how products
              differ both in terms of features and price.
            </li>
          </ul>
          <p>
            Don’t use pricing tables when the user can possibly buy more than
            one product at a time.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>A pricing table consists of the following elements:</p>
          <ul>
            <li>
              <strong>Table header: </strong> it can contain the price plan name
              in 1.25rem(20px) font size and a plan description with 0.875rem
              (14px) font size, the price with 3.65rem (60px) font size, and an
              action button.
            </li>
            <li>
              <strong>Table body: </strong> Contains a list of the pricing plan
              features in 1rem (16px) font size.
            </li>
            <li>
              <strong>Table footer (optional):</strong> contains a link to the
              detailed view of the pricing plan (0.875rem -14px font size).
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>No accessibility roles are required.</p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
