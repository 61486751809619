import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function PricingAllDetailedPreview(props) {
  const {
    PricingBasicDetailed,
    PricingPrimaryDetailed,
    PricingPrimaryDetailedContactUs,
  } = props

  return (
    <>
      {/*<!-- Component: All Basic--> */}
      <div className="container m-auto px-6">
        <div className="grid grid-cols-4 gap-6 lg:grid-cols-12">
          <div className="col-span-4">
            <CopyComponent
              copyToClipboardCode={PricingBasicDetailed}
              componentName="PricingBasicDetailed"
            >
              <div className="lg:max-md-full mx-auto w-full max-w-sm overflow-hidden rounded bg-white text-slate-500 shadow-lg shadow-slate-200">
                <div className="flex flex-col">
                  <header className="flex flex-col gap-6 p-6 text-slate-400">
                    <h3 className="text-xl font-bold text-slate-700">
                      Basic
                      <span className="block text-sm font-normal text-slate-400">
                        Ideal for individual developers.
                      </span>
                    </h3>
                    <h4>
                      <span className="text-3xl">$</span>
                      <span className="text-5xl font-bold tracking-tighter text-slate-700 transition-all duration-300 lg:text-6xl">
                        15
                      </span>
                      <span className="text-sm">/month</span>
                    </h4>
                    <button className="inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white shadow-lg shadow-emerald-100 transition duration-300 hover:bg-emerald-600 hover:shadow-md hover:shadow-emerald-100 focus:bg-emerald-700 focus:shadow-md focus:shadow-emerald-100 focus-visible:outline-none">
                      <span>Try for free</span>
                    </button>
                  </header>
                  <div className="p-6">
                    <ul className="space-y-4">
                      <li className="flex items-start gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-6 w-6 shrink-0 p-1 text-emerald-500"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Fully featured headless CMS, now including GraphQL
                      </li>
                      <li className="flex items-start gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-6 w-6 shrink-0 p-1 text-emerald-500"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Language and framework agnostic
                      </li>
                    </ul>
                  </div>
                  <footer className="border-t border-slate-200 bg-slate-100 p-6 text-center text-sm">
                    <a
                      className="text-emerald-500 transition-colors duration-300 hover:text-emerald-600 focus:text-emerald-700"
                      href="#"
                    >
                      See all features
                    </a>
                  </footer>
                </div>
              </div>
            </CopyComponent>
          </div>
          <div className="col-span-4">
            <CopyComponent
              copyToClipboardCode={PricingPrimaryDetailed}
              componentName="PricingPrimaryDetailed"
            >
              <div className="lg:max-md-full mx-auto w-full max-w-sm overflow-hidden rounded bg-emerald-500 text-emerald-100 shadow-xl shadow-emerald-100">
                <div className="flex flex-col">
                  <header className="flex flex-col gap-6 p-6 text-emerald-200">
                    <h3 className="text-left text-xl font-bold text-emerald-50">
                      Team
                      <span className="block text-sm font-normal text-emerald-200">
                        Ideal for a small team.
                      </span>
                    </h3>
                    <h4>
                      <span className="text-3xl">$</span>
                      <span className="text-5xl font-bold tracking-tighter text-emerald-50 transition-all duration-300 lg:text-6xl">
                        25
                      </span>
                      <span className="text-sm">/month</span>
                    </h4>
                    <button className="inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 shadow-lg shadow-emerald-600 transition duration-300 hover:text-emerald-600 hover:shadow-md hover:shadow-emerald-600 focus:text-emerald-700 focus:shadow-md focus:shadow-emerald-600 focus-visible:outline-none">
                      <span>Try for free</span>
                    </button>
                  </header>
                  <div className="p-6">
                    <ul className="space-y-4">
                      <li className="flex items-start gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-6 w-6 shrink-0 p-1 text-emerald-50"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Decoupled microservices architecture evolves with your
                        project
                      </li>

                      <li className="flex items-start gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-6 w-6 shrink-0 p-1 text-emerald-50"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Expanded authoring roles and locales to support basic
                        publishing workflows
                      </li>

                      <li className="flex items-start gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-6 w-6 shrink-0 p-1 text-emerald-50"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Easy payment via credit card
                      </li>
                    </ul>
                  </div>
                  <footer className="border-t border-emerald-600 bg-emerald-600/50 p-6 text-center text-sm">
                    <a
                      className="text-emerald-100 transition-colors duration-300 hover:text-emerald-50 focus:text-emerald-50"
                      href="#"
                    >
                      See all features
                    </a>
                  </footer>
                </div>
              </div>
            </CopyComponent>
          </div>
          <div className="col-span-4">
            <CopyComponent
              copyToClipboardCode={PricingPrimaryDetailedContactUs}
              componentName="PricingPrimaryDetailedContactUs"
            >
              <div className="lg:max-md-full mx-auto w-full max-w-sm overflow-hidden rounded bg-white text-slate-500 shadow-lg shadow-slate-200">
                <div className="flex flex-col">
                  <header className="flex flex-col gap-6 p-6 text-slate-400">
                    <h3 className="text-left text-xl font-bold text-slate-700">
                      Premium
                      <span className="block text-sm font-normal text-slate-400">
                        Ideal for organizations.
                      </span>
                    </h3>
                    <h4>
                      <span className="text-3xl">$</span>
                      <span className="text-5xl font-bold tracking-tighter text-slate-700 transition-all duration-300 lg:text-6xl">
                        85
                      </span>
                      <span className="text-sm">/month</span>
                    </h4>
                    <button className="inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white shadow-lg shadow-emerald-100 transition duration-300 hover:bg-emerald-600 hover:shadow-md hover:shadow-emerald-100 focus:bg-emerald-700 focus:shadow-md focus:shadow-emerald-200 focus-visible:outline-none">
                      <span>Contact Us</span>
                    </button>
                  </header>
                  <div className="p-6">
                    <ul className="space-y-4">
                      <li className="flex items-start gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-6 w-6 shrink-0 p-1 text-emerald-500"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Open platform with SDKs, App Framework and design system
                      </li>

                      <li className="flex items-start gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-6 w-6 shrink-0 p-1 text-emerald-500"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Access technical support via email or in-app messaging
                      </li>

                      <li className="flex items-start gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-6 w-6 shrink-0 p-1 text-emerald-500"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Optimize your governance model with enough users, roles
                        & permissions, and locales for the largest global
                        organizations
                      </li>
                      <li className="flex items-start gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-6 w-6 shrink-0 p-1 text-emerald-500"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Access to our Customer Success Managers, Solution
                        Architects and Professional Services offerings
                      </li>
                    </ul>
                  </div>
                  <footer className="border-t border-slate-200 bg-slate-100 p-6 text-center text-sm">
                    <a
                      className="text-emerald-500 transition-colors duration-300 hover:text-emerald-600 focus:text-emerald-700"
                      href="#"
                    >
                      See all features
                    </a>
                  </footer>
                </div>
              </div>
            </CopyComponent>
          </div>
        </div>
      </div>
      {/*<!-- End All Basic--> */}
    </>
  )
}
